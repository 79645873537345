<template>
  <div class="my_log">
    <div class="warpper">
      <h2 class="title">日志记录</h2>
      <div class="log-record">
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="textarea"
          placeholder="请输入"
          maxlength="500"
        ></textarea>
        <button class="save">保存</button>
      </div>
      <h2 class="title">我的日志</h2>
      <p style="margin:10px 50px" class="f16">暂无内容</p>
      <!-- <div class="log-section">
        <div class="log-item">
          <div class="subtitle flex-align-between">
            <h3 class="f20">1、我的日志</h3>
            <div class="summary">
              <span class="updateTime">2020.09.01 13:21</span>
              <span class="delete csp">删除</span>
            </div>
          </div>
          <div class="content">
            <p class="text">
              暂无内容
            </p>
            <div class="btn-box">
              <button>收起</button>
            </div>
          </div>
        </div> -->
        <!-- <div class="log-item">
          <div class="subtitle flex-align-between">
            <h3 class="f20">2、我的日志</h3>
            <div class="summary">
              <span class="updateTime">2020.09.01 13:25</span>
              <span class="delete">删除</span>
            </div>
          </div>
          <div class="content" :class="{ content_fold: isFold }">
            <p class="text">
              暂无内容
            </p>
            <div class="btn-box">
              <button @click="tofold()" :class="{ 'not-dot': hasDot }">
                {{ fold }}
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFold: true,
      fold: "展开",
      hasDot: false
    };
  },
  watch: {},
  computed: {},
  methods: {
    tofold() {
      this.isFold = !this.isFold;
      this.isFold === true ? (this.fold = "展开") : (this.fold = "收起");
    },
    hadElementHeight() {
      this.$nextTick(function() {
        let _elementHeight = document.getElementsByClassName("text");
        for (let i = 0; i < _elementHeight.length; i++) {
          if (_elementHeight[i].scrollHeight <= 48) {
            this.hasDot = true;
          } else {
            this.hasDot = false;
          }
        }
      });
    }
  },
  mounted() {
    this.hadElementHeight();
  }
};
</script>

<style lang="less">
.my_log {
  border-radius: 10px;
  // box-shadow: 0px 5px 23px 0px rgba(22, 115, 255, 0.1);
  max-width: 819px;
  width: 819px;

  background: #ffffff;

  .warpper {
    padding-right: 9px;
  }

  .title {
    border-bottom: 1px solid #f9f9f9;
    padding-left: 35px;
    line-height: 69px;

    font-size: 24px;
  }

  .log-record {
    padding: 36px 26px 26px 36px;

    .textarea {
      resize: none;
      overflow-y: auto;
      margin-bottom: 50px;
      border: none;
      border-radius: 10px;
      padding: 17px;
      width: 95%;
      max-height: 161px;
      font-size: 16px;
      background-color: #ecebeb;
    }

    .save {
      display: block;

      margin-left: auto;
      box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
      border-radius: 5px;
      padding: 0 62px;
      line-height: 42px;

      font-size: 18px;
      color: #ffffff;
      background: linear-gradient(90deg, #2272ec, #3a7bf6);
      cursor: pointer;
    }
  }

  .log-section {
    padding: 15px 35px 70px 33px;
    .log-item {
      margin-bottom: 47px;
    }

    .summary {
      font-size: 14px;
      color: #666;
      span {
        margin-right: 17px;
      }
    }

    .content {
      margin-top: 17px;
      border: 1px solid #efeeee;
      border-radius: 10px;
      padding: 13px 12px 12px 17px;
      min-height: 100px;

      font-size: 16px;
      color: #666;

      button {
        display: block;

        margin-left: auto;
        color: #2573ed;
        cursor: pointer;
      }
    }

    .content_fold {
      padding-bottom: 15px;
      position: relative;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .btn-box {
        position: absolute;
        bottom: 15px;
        right: 12px;
        background-color: #fff;

        button {
          display: inline-block;
          margin-top: 0;
          line-height: 25px;

          &::before {
            content: "......";
            margin-right: 8px;
          }
        }

        .not-dot::before {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .my_log {
    max-width: 660px;
    width: 660px;
  }
}
</style>
